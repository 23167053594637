import React,{useState} from 'react';

import logo_large from './images/logo_large.png';
import logo_small from './images/logo_sml.png';

import './App.css';

import AbrooEventEditor from './components/AbrooEventEditor';

//import clonedeep from 'lodash.clonedeep'

/*var myCar = {};
myCar.make = 'Ford';
myCar.model = 'Mustang';
myCar.year = 1969;

var otherCar = clonedeep(myCar)
myCar.year = 1970;*/

/**
 * JavaScript Get URL Parameter
 * based on https://www.kevinleary.net/javascript-get-url-parameters/
 *
 * @param String prop The specific URL parameter you want to retreive the value for
 * @return String|Object If prop is provided a string value is returned, otherwise an object of all properties is returned
 */
function getUrlParam(prop) {
  // No parameters
  if (window.location.search === "") return null;

  const search = window.location.search.substring(1);

  // Multiple parameters
  if (search.includes("&")) {
    const params = new URLSearchParams(search);
    return params.get(prop);
  }

  // Single parameter
  const parts = search.split("=");
  const key = parts[0];
  const val = parts[1];

  if (key === prop) {
    return val;
  }

  return null;
}



function App() {
  const evtShortcutFromURL =  getUrlParam('evt');

  const [initialEventInfo, setInitialEventInfo] = useState(evtShortcutFromURL);

  function JumpButtonClicked() {
    let shortcutentrynode = document.getElementById('eventshortcutentry');
    
    setInitialEventInfo(shortcutentrynode.value);
  }
  
  if (initialEventInfo) {
    return (
      <div className="App">
        <header className="App-header-small">
          <img src={logo_small} className="App-logo-small" alt="logo" />
        </header>
        <AbrooEventEditor shortcut={initialEventInfo}  clearFn={()=> {setInitialEventInfo(false);}} />
      </div>
    );
  } else {
     return (
      <div className="App">
        <header className="App-header-large">
          <img src={logo_large} className="App-logo-large" alt="logo" />
        </header>
        <button onClick={() => {setInitialEventInfo("_New");}}>Create New Event</button>
        <p>Or jump to existing event:</p>
        <p>Event Shortcut: <input id="eventshortcutentry" type="text" defaultValue="" />
           <button onClick={() => {JumpButtonClicked();}}>Jump to Event</button>
        </p>
      </div>
    );
  }
}

export default App;
