import React, {} from 'react';
import loadingspinner from './Spinner-1s-200px.svg';
function AbrooInflightSpinner(props) {
  let visibilityVal = "hidden";
  
  if (props.ops && props.ops.length > 0) {
    visibilityVal = "visible";
  }
  return(<div style={{width: "5em", height: "5em",   display: "inline-block", alignItems: "center",  justifyContent: "center"}}>
           <img style={{width: "4em", height: "4em", margin: 0, padding: 0, visibility: visibilityVal}} src={loadingspinner} className="loadingspinner" alt="loading"
                onClick={()=>{console.log("Inflight list (len="+props.ops.length+"): "+JSON.stringify(props.ops));}} />
         </div>);
}

export default AbrooInflightSpinner;
