//import React from 'react';

const AbrooVoteValues = {
  "yes": 16,
  "inconvenient": 8,
  "no": 0
};

const AbrooLogLevels = {
  "fatalerror":    1,
  "error":         3,
  "info":          5,
  "debug":         8,
  "verbosedebug": 10,
};

const AbrooAPIReducer = (state, action) => {
  switch (action.type) {
    case 'startNewEvent':
      return actionStartNewEvent(state, action);

    case 'completedNewEvent':
      return actionCompletedNewEvent(state, action);

    case 'startLoadEvent':
      return actionStartLoadEvent(state, action);

    case 'completedLoadEvent':
      return actionCompletedLoadEvent(state, action);
    
    case 'startAlterEvent':
      return actionStartAlterEvent(state, action);
      
    case 'completedAlterEvent':
      return actionCompletedAlterEvent(state, action);
    
    case 'startAddCandidate':
      return actionStartAddCandidate(state, action);
      
    case 'completedAddCandidate':
      return actionCompletedAddCandidate(state, action);

    case 'startAlterCandidate':
      return actionStartAlterCandidate(state, action);

    case 'completedAlterCandidate':
      return actionCompletedAlterCandidate(state, action);

    case 'startDelCandidate':
      return actionStartDelCandidate(state, action);

    case 'completedDelCandidate':
      return actionCompletedDelCandidate(state, action);
      
    case 'startAddVoter': 
      return actionStartAddVoter(state, action);

    case 'completedAddVoter':
      return actionCompletedAddVoter(state, action);

    case 'startAlterVoter':
      return actionStartAlterVoter(state, action);

    case 'completedAlterVoter':
      return actionCompletedAlterVoter(state, action);

    case 'startDelVoter':
      return actionStartDelVoter(state, action);

    case 'completedDelVoter':
      return actionCompletedDelVoter(state, action);
      
    case 'startAddChoice':
      return actionStartAddChoice(state, action);

    case 'completedAddChoice':
      return actionCompletedAddChoice(state, action);

    case 'startAlterChoice':
      return actionStartAlterChoice(state, action);

    case 'completedAlterChoice':
      return actionCompletedAlterChoice(state, action);
    
    case 'startDelChoice':
      return actionStartDelChoice(state, action);
    
    case 'completedDelChoice':
      return actionCompletedDelChoice(state, action);

    case 'failedLoadEvent': 
    case 'failedAlterEvent':
    case 'failedAddCandidate':
    case 'failedAlterCandidate':
    case 'failedDelCandidate':
    case 'failedAddVoter':
    case 'failedAlterVoter':
    case 'failedDelVoter':
    case 'failedAddChoice':
    case 'failedAlterChoice':
    case 'failedDelChoice':
      const failedInflightOps = removeInflightOperation(state.inflightOps, action.id);
        //TODO: Alert the user.
        log(AbrooLogLevels['error'], "Error: Failed action: "+JSON.stringify(action));

      return {
        ...state,
        inflightOps: failedInflightOps,
        numErrors: (state.numErrors+1),
      };
      
    case 'log':
      log(action.level, action.message);
      return state;
      
    default:
      throw new Error();
  }
};
function actionStartNewEvent(state, action) {
 log(AbrooLogLevels['info'], "Starting new event");
      
 const inflightOps = addInflightOperation(state.inflightOps, action.id);
      
 return {
   ...state,
   inflightOps: inflightOps,
   reloadRequested: false, //we are requesting the load now...
 };
}

function actionCompletedNewEvent(state, action) {
  log(AbrooLogLevels['info'], "Completed new event");
  
  const inflightOps = removeInflightOperation(state.inflightOps, action.id);
  
  //Update the url to include the evt shortcut
  let shortcutid = action.payload.shortcut.shortcutid;
  
  const url = new URL(window.location);
  url.searchParams.set('evt', shortcutid);
  window.history.pushState({}, '', url);
  
  return {
    ...state,
    inflightOps: inflightOps,
    evtData: action.payload,
    tabular: regenerateTabular(action.payload, state.uiData),
    shortcut: shortcutid,
  };
}

function actionStartLoadEvent(state, action) {
 log(AbrooLogLevels['info'], "Starting load event: "+action.id);
      
 const inflightOps = addInflightOperation(state.inflightOps, action.id);
      
 return {
   ...state,
   inflightOps: inflightOps,
   reloadRequested: false, //we are requesting the load now...
 };
}

function actionCompletedLoadEvent(state, action) {
  log(AbrooLogLevels['info'], "Completed load event: "+action.id);
  
  const inflightOps = removeInflightOperation(state.inflightOps, action.id);

  const url = new URL(window.location);
  url.searchParams.set('evt', action.shortcutid);
  window.history.pushState({}, '', url);
  
  return {
    ...state,
    inflightOps: inflightOps,
    evtData: action.payload,
    tabular: regenerateTabular(action.payload, state.uiData)
  };
}


function actionStartAlterEvent(state, action) {
  log(AbrooLogLevels['info'], "Starting alteration of event. Using action id: "+action.id);
  const inflightOps = addInflightOperation(state.inflightOps, action.id);
  
  let newEvtData = {
      ...state.evtData,
      evt: action.event,
    };

  return {
    ...state,
    inflightOps: inflightOps,
    evtData: newEvtData,
    tabular: regenerateTabular(newEvtData, state.uiData),
  };
}

function actionCompletedAlterEvent(state, action) {
  log(AbrooLogLevels['info'], "Completing alteration of event with action id: "+action.id);
  const inflightOps = removeInflightOperation(state.inflightOps, action.id);

  return {
    ...state,
    inflightOps: inflightOps,
  };
}
  

function actionStartAddCandidate(state,action) {
  log(AbrooLogLevels['info'], "Starting addition of new candidate: "+action.candidate['localcandidateid']);
  const inflightOps = addInflightOperation(state.inflightOps, action.id);
  
  let candidateslist = state.evtData.candidates;
  if (candidateslist) {
    candidateslist = candidateslist.slice(); //copy candidates array
  } else {
    candidateslist = [];
  }
  candidateslist.push(action.candidate);
  
  let newEvtData = {
    ...state.evtData,
    candidates: candidateslist,
  };
  
  return {
    ...state,
    inflightOps: inflightOps,
    evtData: newEvtData,
    tabular: regenerateTabular(newEvtData, state.uiData),
  };
}

function actionCompletedAddCandidate(state, action) {
  log(AbrooLogLevels['info'], "Completing addition of new candidate: "+action.candidate['localcandidateid']+" - assigned id: "+action.payload.id);
  const inflightOps = removeInflightOperation(state.inflightOps, action.id);

  let candidateslist =  state.evtData.candidates.slice(); //copy array

  candidateslist = candidateslist.map((cand) => {
                                     if (cand['localcandidateid'] === action.candidate['localcandidateid']) {
                                       cand['candidateid'] = action.payload.id;
                                       return cand;
                                     } else {
                                       return cand;
                                     } });
  let newEvtData = {
    ...state.evtData,
    candidates: candidateslist,
  };
  
  return {
    ...state,
    inflightOps: inflightOps,
    evtData: newEvtData,
    tabular: regenerateTabular(newEvtData, state.uiData),
  };
}    

function actionStartAlterCandidate(state, action) {
  log(AbrooLogLevels['info'], "Starting alteration of candidate: "+action.candidate['candidateid']);
  const sACaninflightOps = addInflightOperation(state.inflightOps, action.id);

  let sAcandidateslist =  state.evtData.candidates.slice(); //copy array

  sAcandidateslist = sAcandidateslist.map((cand) => {
                                     if (cand['candidateid'] === action.candidate['candidateid']) {
                                       return action.candidate;
                                     } else {
                                       return cand;
                                     } });
  
  let sACanNewEvtData = {
      ...state.evtData,
      candidates: sAcandidateslist,
    };
  return {
    ...state,
    inflightOps: sACaninflightOps,
    evtData: sACanNewEvtData,
    tabular: regenerateTabular(sACanNewEvtData, state.uiData),
  };
}

function actionCompletedAlterCandidate(state, action) {
  log(AbrooLogLevels['info'], "Completing alteration of candidate: "+action.candidate['candidateid']);
  const inflightOps = removeInflightOperation(state.inflightOps, action.id);

  return {
    ...state,
    inflightOps: inflightOps,
  };
}
      

function actionStartDelCandidate(state, action) {
  log(AbrooLogLevels['info'], "Starting deletion of candidate: "+action.candidateid);
  
  const sDCinflightOps = addInflightOperation(state.inflightOps, action.id);
  
  let sDcandidatesList = state.evtData.candidates.slice(); //copy array
  sDcandidatesList = sDcandidatesList.filter((cand) => {
                          if (cand['candidateid'] === action.candidateid) {
                            return false;
                          }
                          return true;
                        });
  
  let newEvtData = {
    ...state.evtData,
    candidates: sDcandidatesList,
  };
  return {
    ...state,
    inflightOps: sDCinflightOps,
    evtData: newEvtData,
    tabular: regenerateTabular(newEvtData, state.uiData),
  };
}

function actionCompletedDelCandidate(state, action) {
  log(AbrooLogLevels['info'], "Completing deletion of candidate: "+action.candidateid);
  const cDCinflightOps = removeInflightOperation(state.inflightOps, action.id);
  
  let errors = state.numErrors;
  
  if (!action.payload['numdel'] || action.payload['numdel'] !== 1 ){
    //TODO: Alert the user.
    log(AbrooLogLevels['error'], "Error: deletion of candidate: "+action.candidateid+" deleted "+action.payload['numdel']+" records.");
    errors += 1;
  }
  
  return {
      ...state,
      inflightOps: cDCinflightOps,
      numErrors:   errors,
  };
}

function actionStartAddVoter(state, action) {
  log(AbrooLogLevels['info'], "Starting addition of new voter: "+action.voter['localvoterid']);
  const sAVinflightOps = addInflightOperation(state.inflightOps, action.id);
  
  let voterslist = state.evtData.voters;
  if (voterslist) {
    voterslist = voterslist.slice(); //copy voters array
  } else {
    voterslist = [];
  }
  voterslist.push(action.voter);

  let newEvtData = {
    ...state.evtData,
    voters: voterslist,
  };

  return {
    ...state,
    inflightOps: sAVinflightOps,
    evtData: newEvtData,
    tabular: regenerateTabular(newEvtData, state.uiData),
  };
}

function actionCompletedAddVoter(state, action) {
  log(AbrooLogLevels['info'], "Completing addition of new voter: "+action.voter['localvoterid']+" - assigned id: "+action.payload.id);
  const inflightOps = removeInflightOperation(state.inflightOps, action.id);

  let voterslist =  state.evtData.voters.slice(); //copy array

  voterslist = voterslist.map((voter) => {
                                     if (voter['localvoterid'] === action.voter['localvoterid']) {
                                       voter['voterid'] = action.payload.id;
                                       return voter;
                                     } else {
                                       return voter;
                                     } });

  let newEvtData = {
      ...state.evtData,
      voters: voterslist,
    };
    
  return {
    ...state,
    inflightOps: inflightOps,
    evtData: newEvtData,
    tabular: regenerateTabular(newEvtData, state.uiData),
  };
}

function actionStartAlterVoter(state, action) {
  log(AbrooLogLevels['info'], "Starting alteration of voter: "+action.voter['voterid']);
  const sAVrinflightOps = addInflightOperation(state.inflightOps, action.id);

  let sAvoterslist =  state.evtData.voters.slice(); //copy array

  sAvoterslist = sAvoterslist.map((voter) => {
                                     if (voter['voterid'] === action.voter['voterid']) {
                                       return action.voter;
                                     } else {
                                       return voter;
                                     } });
  
  let sAVNewEvtData = {
      ...state.evtData,
      voters: sAvoterslist,
    };
  return {
    ...state,
    inflightOps: sAVrinflightOps,
    evtData: sAVNewEvtData,
    tabular: regenerateTabular(sAVNewEvtData, state.uiData),
  };
}

function actionCompletedAlterVoter(state, action) {
  log(AbrooLogLevels['info'], "Completing alteration of voter: "+action.voter['voterid']);
  const cAlVinflightOps = removeInflightOperation(state.inflightOps, action.id);

  return {
    ...state,
    inflightOps: cAlVinflightOps,
  };
}

function actionStartDelVoter(state, action) {
  log(AbrooLogLevels['info'], "Starting deletion of voter: "+action.voterid);
  
  const inflightOps = addInflightOperation(state.inflightOps, action.id);
  
  let votersList = state.evtData.voters.slice(); //copy array
  votersList = votersList.filter((voter) => {
                          if (voter['voterid'] === action.voterid) {
                            return false;
                          }
                          return true;
                        });
  
  let newEvtData = {
    ...state.evtData,
    voters: votersList,
  };
  return {
    ...state,
    inflightOps: inflightOps,
    evtData: newEvtData,
    tabular: regenerateTabular(newEvtData, state.uiData),
  };
}

function actionCompletedDelVoter(state, action) {
  log(AbrooLogLevels['info'], "Completing deletion of voter: "+action.voterid);
  const inflightOps = removeInflightOperation(state.inflightOps, action.id);
  
  let errors = state.numErrors;
  
  if (!action.payload['numdel'] || action.payload['numdel'] !== 1 ){
    //TODO: Alert the user.
    log(AbrooLogLevels['error'], "Error: deletion of voter: "+action.voterid+" deleted "+action.payload['numdel']+" records.");
    errors += 1;
  }
  
  return {
      ...state,
      inflightOps: inflightOps,
      numErrors:   errors,
  };
}

function actionStartAddChoice(state, action) {
  log(AbrooLogLevels['info'], "Starting addition of new choice: "+action.choice['localchoiceid']+
        ' voter='+action.choice.voterid+' cand='+action.choice.candidateid+' value: '+action.choice.votevalue);
  const sACinflightOps = addInflightOperation(state.inflightOps, action.id);

  let choiceslist = state.evtData.choices;
  if (choiceslist) {
    choiceslist = choiceslist.slice(); //copy array
  } else {
    choiceslist = [];
  }
  choiceslist.push(action.choice);

  let sACNewEvtData = {
    ...state.evtData,
    choices: choiceslist,
  };

  return {
    ...state,
    inflightOps: sACinflightOps,
    evtData: sACNewEvtData,
    tabular: regenerateTabular(sACNewEvtData, state.uiData),
  };
}

function actionCompletedAddChoice(state, action) {
  log(AbrooLogLevels['info'], "Completing addition of new choice: "+action.choice['localchoiceid']+" - assigned id: "+action.payload.id);
  const cAChinflightOps = removeInflightOperation(state.inflightOps, action.id);

  let cAchoiceslist =  state.evtData.choices.slice(); //copy array

  cAchoiceslist = cAchoiceslist.map((choice) => {
                                     if (choice['localchoiceid'] === action.choice['localchoiceid']) {
                                       choice['choiceid'] = action.payload.id;
                                       return choice;
                                     } else {
                                       return choice;
                                     } });

  let newEvtData = {
    ...state.evtData,
    choices: cAchoiceslist,
  }
  return {
    ...state,
    inflightOps: cAChinflightOps,
    evtData: newEvtData,
    tabular: regenerateTabular(newEvtData, state.uiData),
  };
}

function actionStartAlterChoice(state, action) {
  log(AbrooLogLevels['info'], "Starting alteration of choice: "+action.choice['choiceid']);
  const sAChinflightOps = addInflightOperation(state.inflightOps, action.id);

  let sAchoiceslist =  state.evtData.choices.slice(); //copy array

  sAchoiceslist = sAchoiceslist.map((choice) => {
                                     if (choice['choiceid'] === action.choice['choiceid']) {
                                       choice['votevalue'] = action.choice['votevalue'];
                                       return choice;
                                     } else {
                                       return choice;
                                     } });
  
  let sANewEvtData = {
      ...state.evtData,
      choices: sAchoiceslist,
    };
  return {
    ...state,
    inflightOps: sAChinflightOps,
    evtData: sANewEvtData,
    tabular: regenerateTabular(sANewEvtData, state.uiData),
  };
}

function actionCompletedAlterChoice(state, action) {
  log(AbrooLogLevels['info'], "Completing alteration of choice: "+action.choice['choiceid']);
  const cAlChinflightOps = removeInflightOperation(state.inflightOps, action.id);

  return {
    ...state,
    inflightOps: cAlChinflightOps,
  };
}
    
function actionStartDelChoice(state, action) {
  log(AbrooLogLevels['info'], "Starting deletion of choice: "+action.delchoice['choiceid']);
  
  const sDChinflightOps = addInflightOperation(state.inflightOps, action.id);

  let sDchoicesList = state.evtData.choices.slice(); //copy array
  sDchoicesList = sDchoicesList.filter((choice) => {
                          if (choice['choiceid'] === action.delchoice['choiceid']) {
                            return false;
                          }
                          return true;
                        });
  
  let sDNewEvtData = {
    ...state.evtData,
    choices: sDchoicesList,
  };
  return {
    ...state,
    inflightOps: sDChinflightOps,
    evtData: sDNewEvtData,
    tabular: regenerateTabular(sDNewEvtData, state.uiData),
  };
}
    
function actionCompletedDelChoice(state, action) {
  log(AbrooLogLevels['info'], "Finishing deletion of choice: "+action.delchoice['choiceid']);
  const cDChinflightOps = removeInflightOperation(state.inflightOps, action.id);
  
  let cDCherrors = state.numErrors;
  
  if (!action.payload['numdel'] || action.payload['numdel'] !== 1 ){
    //Oh dear
    cDCherrors += 1;
  }
  
  return {
    ...state,
    inflightOps: cDChinflightOps,
    numErrors:   cDCherrors,
  };
}

function log(level, messagestr) {
  const consoleloglevel = AbrooLogLevels['info'];
  
  if (level <= consoleloglevel) {
    console.log('log('+level+'): '+messagestr);
  }
}

function addInflightOperation(currInflightOps, newOpId) {
  let newInflightList = null;
   
  //log(AbrooLogLevels['error'], "Adding inflight op "+newOpId);

  if (newOpId === null || newOpId === "") {
    log(AbrooLogLevels['error'], "Trying to add inflight operation  but no valid id supplied");
  }
  
  if (currInflightOps != null) {
    newInflightList = currInflightOps.slice();
  } else {
    newInflightList = [];
  }
  newInflightList.push(newOpId);
  
  return newInflightList;
}

function removeInflightOperation(currInflightOps, completedOpId) {

  //log(AbrooLogLevels['error'], "Trying to remove operation "+completedOpId+". Current operation list (len="+currInflightOps.length+"):"+JSON.stringify(currInflightOps));
  
  if (currInflightOps == null) {
    log(AbrooLogLevels['error'], "Trying to remove operation "+completedOpId+" but list is empty");
    return null;
  }
  let numRemoves = 0;
  const newInflightList = currInflightOps.filter((op) => {
                              if (op === completedOpId) {
                                numRemoves += 1;
                                return false;
                              }
                              return true;
                            });
                            
  if (numRemoves !== 1) {
    log(AbrooLogLevels['error'], "Trying to remove operation "+completedOpId+" but removed "+numRemoves+" entries.");
    log(AbrooLogLevels['error'], "Current operation list (len="+newInflightList.length+"):"+JSON.stringify(newInflightList));
  }
  return newInflightList;
}
function regenerateTabular(evtData,uiData) {
   log(AbrooLogLevels['verbosedebug'], 'Starting creating tabular data.');
   let newtabular = { columns: [],
                      data:    []};

   //Add the person name column
   newtabular.columns.push({
     key: "voterName",
     dataKey: "voterName",
     title: "Person"});

   evtData.candidates.forEach(function(cand,index) {
     newtabular.columns.push({
       key: "cand_"+cand.candidateid,
       dataKey: "cand_"+cand.candidateid,
       title: cand.valuetext,
       candidateinfo: cand,
     });
   });
   
   evtData.voters.forEach(function(voter,idx) {
     let voterdata = { voterName: voter.votername,
                       id: "voter_"+voter.voterid,
                       voterinfo: voter, };
      
     evtData.choices.forEach(function(choice,idx) {
       if (choice.voterid === voter.voterid) {
         log(AbrooLogLevels['verbosedebug'],'Table data entry: Choice:'+choice.choiceid+' v='+choice.voterid+'c='+choice.candidateid+' has value: '+choice.votevalue);     
         voterdata['cand_'+choice.candidateid] = { value: choice.votevalue,
                                                   choice: choice};
       }  
     });
     
     newtabular.data.push(voterdata);
   });
   log(AbrooLogLevels['verbosedebug'], 'Finished creation of tabular data.');
   if (uiData && uiData.uiProcessTabularData) {
     return uiData.uiProcessTabularData(newtabular, evtData);
   }
   return newtabular;
}
  

export default AbrooAPIReducer;
export {AbrooVoteValues,AbrooLogLevels};
