import React, {} from 'react';
import styled from 'styled-components';
import { Modal } from 'react-overlays'


const Backdrop = styled("div")`
  position: fixed;
  z-index: 1040;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.5;
`;

const StyledModal = styled(Modal)`
  position: fixed;
  width: 20em;
  height: 16em;
  top: 50%;
  left: 50%;
  margin-top: -8em; //half height
  margin-left: -10em; //half width
  z-index: 1040;
  border: 1px solid #e5e5e5;
  background-color: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  padding: 20px;
`;

function SaveVoterModal(voter, saveFn) {
  const nameinputtext = document.getElementById('editvotername').value;
  
  let voterinfo = {};
  
  voterinfo['votername'] = nameinputtext;

  if (   voter
      && voter.voterid !== 0) {
    voterinfo['voterid'] = voter.voterid;
  }
  
  saveFn(voterinfo);
}



function EditVoterModal(props) {
  const renderBackdrop = (bkgprops) => <Backdrop {...bkgprops} />;

  //console.log("edit voter modal props: "+JSON.stringify(props));
  let titleBanner;
  let defaultname;
  let originalvoter;

  if (   props.voterInfo 
      && props.voterInfo.voter
      && props.voterInfo.voter.voterid !== 0) {
    titleBanner = <h4 id="modal-label">Update Person:</h4>
    defaultname       = props.voterInfo.voter.votername;
    originalvoter = props.voterInfo.voter;
    
    // console.log(  'Setting up edit voter modal for voter: '
    //              + props.voterInfo.voter.voterid); 
  } else {
    titleBanner = <h4 id="modal-label">Add a Person:</h4>
    defaultname   = null;
    originalvoter = null;
    
    //console.log(  'Setting up add voter modal'); 
  }
  
  return (
    <StyledModal
          show={props.voterInfo.show}
          onHide={() => props.onHide()}
          renderBackdrop={renderBackdrop}
          aria-labelledby="modal-label"
        >
          <div>
            {titleBanner}
            <p>
              Enter Text or cancel:
            </p>
            <table>
              <tbody>
                <tr>
                  <td>Name:</td>
                  <td><input id="editvotername" type="text" defaultValue={defaultname} /></td>
                </tr>
                <tr>
                  <td>
                    <button
                      type="button"
                      className="btn btn-primary mb-4"
                      onClick={() => {SaveVoterModal(originalvoter, props.voterInfo.savefn);props.onHide()} }
                    >
                      Save
                    </button>
                  </td>
                  <td style={{textAlign: "right"}}>
                    <button
                      type="button"
                      className="btn btn-primary mb-4"
                      onClick={() => props.onHide()}
                    >
                      Cancel
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
    </StyledModal>);
  }

export default EditVoterModal;
