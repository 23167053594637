import React from 'react';
import useAbrooAPI,{AbrooVoteValues, AbrooLogLevels} from '../AbrooAPI';

import AbrooMenu from '../AbrooMenu';
import AbrooInflightSpinner from '../AbrooInflightSpinner';

import EditEventDetail from '../EditEventDetail';
import EditCandidateModal from '../EditCandidateModal';
import EditVoterModal from '../EditVoterModal';

import BaseTable,{AutoResizer, Column} from 'react-base-table';
import 'react-base-table/styles.css';

import  './AbrooEventEditor.css';
import ShareModal from '../ShareModal';
import SuggestOptionModal from '../SuggestOptionModal';

function AbrooEventEditor(editorprops) {
  const uiData = {
    uiProcessTabularData: UIProcessTabularData,
  };
  let shortcut = editorprops.shortcut;
  
  if (shortcut === "_New") {
    //This is passed in to create a new event
    shortcut = null;
  }
  
  const [evtState, abrooFns] = useAbrooAPI(shortcut, uiData); //'MgXcPql2'
  const [candidateModalInfo, setCandidateModalInfo] = React.useState({
                                                         show: false,
                                                         candidate: null,
                                                      });
  const [voterModalInfo, setVoterModalInfo] = React.useState({
                                                         show: false,
                                                         voter: null,
                                                      });
  const [shareModalInfo, setShareModalInfo] = React.useState({show: false});
  const [suggestOptionModalInfo, setSuggestOptionModalInfo] = React.useState({show: false});

  let accesslevel = null;
  
  if (evtState.evtData && evtState.evtData.shortcut) {
    accesslevel=evtState.evtData.shortcut.accesslevel;
  }

  let candidates = "";
  
  const [winDimensions, setWinDimensions] = React.useState({ 
    height: window.innerHeight,
    width: window.innerWidth
  })
  
  React.useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setWinDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
    }, 200)

    window.addEventListener('resize', debouncedHandleResize)

    return _ => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  });
  
//Stops us redrawing so often on window resize, see:
//https://www.pluralsight.com/guides/re-render-react-component-on-window-resize
function debounce(fn, ms) {
  let timer
  return _ => {
    clearTimeout(timer)
    timer = setTimeout(_ => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  };
}
  //The renderer receives props { columns, column, columnIndex, headerIndex, container }
  function CandidateHeaderRenderer(props) {
    //return (<span>{props.column['title']}</span>);
    
    let columntextArray = [];
    
    if (props.column.candidateinfo && props.column.candidateinfo.valuedate) {
      columntextArray.push(props.column.candidateinfo.valuedate);
    }
    if (props.column.candidateinfo && props.column.candidateinfo.valuetime) {
      if (columntextArray.length > 0) {
        columntextArray.push(<br key="vtimebr"/>);
      }
      columntextArray.push(props.column.candidateinfo.valuetime);
    }
    if (props.column.candidateinfo && props.column.candidateinfo.valuetext) {
      if (columntextArray.length > 0) {
        columntextArray.push(<br key="vtextbr"/>);
      }
      columntextArray.push(props.column.candidateinfo.valuetext);
    }

    const editcandidatesetup = () => {
                setCandidateModalInfo({show: true, candidate: props.column.candidateinfo, savefn: abrooFns.editCandidate})};
    const deletecandidateselected = () => {abrooFns.deleteCandidate(props.column.candidateinfo.candidateid)};
    
    let editable = false;
    
    if (   props.columnIndex > 0 
        && abrooFns.allowedToAlter('candidate', props.column.accesslevel)) {
      editable=true;
    }
    
    if (editable) {
        return (<>
                  <span>{columntextArray}</span>
                  <AbrooMenu>
                    {[{'name':'Edit',   hitfn: editcandidatesetup},
                    {'name':'Delete', hitfn: deletecandidateselected} ]}
                  </AbrooMenu>
                </>
               );
    }
    return (<>
              <span>{columntextArray}</span>
            </>
           );
  }
  
  //The renderer receives props:  { cellData, columns, column, columnIndex, rowData, rowIndex, container, isScrolling }
  function DataCellRenderer(props) {
     let votetype = "";
     if (props && props.columns.length > 0) {
       votetype = props.columns[0].votetype;
     }
     if (props.rowData.addvoter) {
       //This is the add Voter row - everything except for the header is blank
       return ("");
     }
     const cand = props.column.candidateinfo.candidateid;
     const voter = props.rowData.voterinfo.voterid;
     const selectId = "data_"+cand+"_"+voter;
     
     let choiceUpdated = () => {
       const sel = document.getElementById(selectId);       
       
       let chosen = "unknown";
       if (sel) {
         chosen = sel.value;
       }
       
       let oldchoice = null;
       
       if (props.cellData != null) {
         oldchoice = props.cellData.choice;
       }

       
       abrooFns.log(AbrooLogLevels['info'], 'choiceUpdated c:'+cand+" v:"+voter+" to:"+chosen);
       if (chosen === "unset") {
         abrooFns.deleteChoice(oldchoice, voter, cand);
       } else {
         for (const property in AbrooVoteValues) {
            if (chosen === property) {
              chosen = AbrooVoteValues[property];
              break;
            }
         }
         abrooFns.setChoice(oldchoice, voter, cand, chosen);
       }
     };
     
     let editable = false;
      
     if (abrooFns.allowedToAlter( 'voter'
                                 , props.column['accesslevel']
                                 , props.rowData.voterinfo.voterid
                                 , props.column[''])) {
        editable = true;
     }
     
     if (votetype === "yesno") {
       let selectedVal = "unset";
       let displayVal  = "";
         
       if (props.cellData) {
         if (props.cellData.value === AbrooVoteValues['yes']) {
           selectedVal = "yes";
           displayVal  = "Yes";
         } else if (props.cellData.value === AbrooVoteValues['no']) {
           selectedVal = "no";
           displayVal  = "No";
         }
       }
       
       if (editable) {
         return (<select id={selectId} value={selectedVal} onChange={choiceUpdated}>
                   <option value="unset"></option>
                   <option value="yes">Yes</option>
                   <option value="no">No</option>
                 </select>);
       } else {
         return (<span>{displayVal}</span>);
       }
     } else if (votetype === "yesnoinconvenient") {
       let selectedVal = "unset";
       let displayVal  = "";
       
       if (props.cellData) {
         if (props.cellData.value === AbrooVoteValues['yes']) {
           selectedVal = "yes";       
           displayVal  = "Yes";
         } else if (props.cellData.value === AbrooVoteValues['no']) {
           selectedVal = "no";
           displayVal  = "No";
         } else if (props.cellData.value === AbrooVoteValues['inconvenient']) {
           selectedVal = "inconvenient";
           displayVal  = "Inconvenient";
         }
       }
       
       if (editable) {
         return (<select id={selectId} value={selectedVal} onChange={choiceUpdated}>
                   <option value="unset"></option>
                   <option value="yes">Yes</option>
                   <option value="inconvenient">Inconvenient</option>
                   <option value="no">No</option>
                 </select>);
       } else {
         return (<span>{displayVal}</span>);
       }
     } else if (votetype === "ranked") {
            return (<select>
               <option value="unset"></option>
               <option value="rank1">1</option>
               <option value="inconvenient">2</option>
               </select>);
     }
     
     return ("Inconsistent event state - loading?");
  }
  
  //The renderer receives props:  { cellData, columns, column, columnIndex, rowData, rowIndex, container, isScrolling }
  function VoterCellRenderer(props) {
    if (props.rowData.addvoter) {
      return (<button onClick={() => {setVoterModalInfo({show: true, voter: null, savefn: abrooFns.addVoter});}}>Add Person</button>);
    } else {
      const editvotersetup = () => {
                  setVoterModalInfo({show: true, voter: props.rowData.voterinfo, savefn: abrooFns.editVoter})};
      const deletevoterselected = () => {abrooFns.deleteVoter(props.rowData.voterinfo.voterid)};
      
      let editable = false;
      
      if (abrooFns.allowedToAlter( 'voter'
                                 , props.column['accesslevel']
                                 , props.rowData.voterinfo.voterid
                                 , props.column[''])) {
        editable = true;
      }
      
      if (editable) {
        return (<>
                  <span>{props.rowData.voterName}</span>
                  <AbrooMenu>
                    {[{'name':'Edit',   hitfn: editvotersetup},
                      {'name':'Delete', hitfn: deletevoterselected} ]}
                  </AbrooMenu>
                </>);
      }
    }
    return (<>
              <span>{props.rowData.voterName}</span>
            </>);

  }
  
  //The renderer receives props { columns, column, columnIndex, headerIndex, container }
  function AddCandidateHeaderRenderer(props) {
    return (<button onClick={() => {/*abrooFns.log(AbrooLogLevels['info'], "Add Candidate Clicked");*/ 
                                    setCandidateModalInfo({show: true, candidate: null, savefn: abrooFns.addCandidate});}}>Add Option</button>);
  }
  
  //If the column is frozen - it's our header col - it needs to come first
  //else compare candidateinfo.valuedate (if not set - goes to end)
  //If tie, compare candidateinfo.valuetime (if not set - goes to end)
  //If still tie, compare valuetext (if not set - goes to end)
  //
  function ColumnSortCompare(col_a, col_b) {
    if (col_a.frozen) {
      //a must be our first column - ensure it stays first
      return -1;
    }
    if (col_b.frozen) {
      //b must be our first column - ensure it stays first
      return 1;
    }
    if (col_a.candidateinfo && col_a.candidateinfo.valuedate) {
      if (col_b.candidateinfo && col_b.candidateinfo.valuedate) {
        if (col_a.candidateinfo.valuedate < col_b.candidateinfo.valuedate) {
          return -1;
        } else if (col_a.candidateinfo.valuedate > col_b.candidateinfo.valuedate) {
          return 1;
        }
      } else {
        //a has a date and b does not - a goes first
        return -1;
      }
    } else if (col_b.candidateinfo && col_b.candidateinfo.valuedate) {
      //b has a date and a does not.... b goes first
      return 1;
    }

    if (col_a.candidateinfo && col_a.candidateinfo.valuetime) {
      if (col_b.candidateinfo && col_b.candidateinfo.valuetime) {
        if (col_a.candidateinfo.valuetime < col_b.candidateinfo.valuetime) {
          return -1;
        } else if (col_a.candidateinfo.valuetime > col_b.candidateinfo.valuetime) {
          return 1;
        }
      } else {
        //a has a time and b does not - a goes first
        return -1;
      }
    } else if (col_b.candidateinfo && col_b.candidateinfo.valuetime) {
      //b has a time and a does not.... b goes first
      return 1;
    }

    if (col_a.candidateinfo && col_a.candidateinfo.valuetext) {
      if (col_b.candidateinfo && col_b.candidateinfo.valuetext) {
        //compare based on: https://fuzzytolerance.info/blog/2019/07/19/The-better-way-to-do-natural-sort-in-JavaScript/
        let answer = col_a.candidateinfo.valuetext.localeCompare(col_b.candidateinfo.valuetext,
               (navigator.languages[0] || navigator.language), {numeric: true, ignorePunctuation: true});

        return answer;
      } else {
        //a has text and b does not - a goes first
        return -1;
      }
    } else if (col_b.candidateinfo && col_b.candidateinfo.valuetext) {
      //b has text and a does not.... b goes first
      return 1;
    }
    // a is equal to b as far as we can tell
    return 0;
  }
  
  //Tabular contains .columns and .data
  function UIProcessTabularData(tabular, evtData) {
    if (tabular.columns.length > 0) {
      tabular.columns[0].frozen = Column.FrozenDirection.LEFT;
    }
    tabular.columns.forEach(function(col,index) {
      col['resizable'] = true;
      col['width']     = 400;
      col['minWidth']  = 200;
      col['headerRenderer'] = CandidateHeaderRenderer;
      
      if (index > 0 ) {
        col['cellRenderer'] = DataCellRenderer;
      } else {
        col['cellRenderer'] = VoterCellRenderer;
      }
      
      if (evtData && evtData.evt) {
        col['votetype']      = evtData.evt.votetype;
        col['accesslevel']   = evtData.shortcut.accesslevel;
        col['accessvoterid'] = evtData.shortcut.voterid;
      }
    });

    tabular.columns.sort(ColumnSortCompare);

    if (abrooFns.allowedToAlter('candidate', evtData.shortcut.accesslevel)) {
      let addCandidateCol = {
        key: "abroo_addCandidate",
        title: "AddCandidate",
        headerRenderer: AddCandidateHeaderRenderer,
        resizable: true,
        width: 200,
        minWidth: 200,
        flexGrow: 0,
      };
    
      tabular.columns.push(addCandidateCol);    
    }
    
    if (abrooFns.allowedToAlter('voter', evtData.shortcut.accesslevel)) {
      let addvoterrowdata = { addvoter: true,
                              id:  "addvoterrow"};
      tabular.data.push(addvoterrowdata);
    }
    return tabular;
  }
  
  function handleEventDetailChange(fieldname, fieldvalue) {
    let newEvtDetails = {
      ...evtState.evtData.evt,
    };
    
    newEvtDetails[fieldname] = fieldvalue;
    
    abrooFns.editEvent(newEvtDetails);
  }
  
  function handleDeleteCandidate(e) {
    e.preventDefault();
    let elem = document.getElementById('delete_candid');
    if (elem) {
      let to_del_id = parseInt(elem.value);
      abrooFns.deleteCandidate(to_del_id);
    }
  }

  const debugComponents = (<div>
                            {evtState.evtData.candidates && <h1>Got Candidates</h1>}
                            {evtState.evtData.choices && <h1>Got Choices</h1>}
                            <ul>{candidates}</ul>
                            <button onClick={() => {setCandidateModalInfo({show: true, candidate: null, savefn: abrooFns.addCandidate});}}>Add Candidate</button> 
                            <input id="delete_candid" type="text" />
                            <button onClick={handleDeleteCandidate}>Delete Candidate</button> 
                           {JSON.stringify(evtState.evtData)}
                           </div>);

  return (
    <div className="App" style={{margin: "auto"}}>
      <EditEventDetail
        label="Title"
        text={evtState.evtData && evtState.evtData.evt && evtState.evtData.evt.title}
        onUpdate={(newvalue) => handleEventDetailChange('title',newvalue)}
        editable={abrooFns.allowedToAlter('event', accesslevel)}
        />
      <EditEventDetail
        label="Description"
        text={evtState.evtData && evtState.evtData.evt && evtState.evtData.evt.description} 
        onUpdate={(newvalue) => handleEventDetailChange('description',newvalue)}
        editable={abrooFns.allowedToAlter('event', accesslevel)}
        />
      <button className="toolbarbutton" onClick={() => {setShareModalInfo({show: true});}} >Invite People/Share</button>
      <button className="toolbarbutton" onClick={() => {setSuggestOptionModalInfo({show: true});}} >Suggest Best Option</button>
      <AbrooInflightSpinner ops={evtState.inflightOps} />
      <div style={{margin: "auto", width: (0.9 * winDimensions.width), height: (0.9 * winDimensions.height)}}>
        <AutoResizer>
          {({ width, height }) => (
            <BaseTable
              width={width}
              height={height}
              maxHeight={0.9 * winDimensions.height}
              columns={evtState.tabular.columns} data={evtState.tabular.data}
              />)}
        </AutoResizer>
      </div>      
      {debugComponents};
      <EditCandidateModal candidateInfo={candidateModalInfo} onHide={() => {setCandidateModalInfo({show: false});}}/>
      <EditVoterModal voterInfo={voterModalInfo} onHide={() => {setVoterModalInfo({show: false});}}/>
      <ShareModal shareInfo={shareModalInfo} onHide={() => {setShareModalInfo({show: false});}}/>
      <SuggestOptionModal modalInfo={suggestOptionModalInfo} evtState={evtState} onHide={() => {setSuggestOptionModalInfo({show: false});}}/>
    </div>
  );
}


export default AbrooEventEditor;
