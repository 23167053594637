import React, {} from 'react';
import styled from 'styled-components';
import { Modal } from 'react-overlays'


const Backdrop = styled("div")`
  position: fixed;
  z-index: 1040;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.5;
`;

const StyledModal = styled(Modal)`
  position: fixed;
  width: 20em;
  height: 16em;
  top: 50%;
  left: 50%;
  margin-top: -8em; //half height
  margin-left: -10em; //half width
  z-index: 1040;
  border: 1px solid #e5e5e5;
  background-color: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  padding: 20px;
`;
function CopyURL() {
  navigator.clipboard.writeText(window.location.href).then(function() {
    /* clipboard successfully set */
  }, function() {
    /* clipboard write failed */
    alert('Writing to Clipboard disallowed');
  });
}

function ShareModal(props) {
  const renderBackdrop = (bkgprops) => <Backdrop {...bkgprops} />;

  return (
    <StyledModal
          show={props.shareInfo.show}
          onHide={() => props.onHide()}
          renderBackdrop={renderBackdrop}
          aria-labelledby="modal-label"
        >
          <div>
            <p>In order to invite people to say which options are best for them, send them the URL for this page - which is:</p>
            <div style={{paddingTop: "0.5em", paddingBottom: "0.5em"}} >
            <strong>{window.location.href}</strong>
            <button onClick={()=> CopyURL()}>Copy to Clipboard</button>
            </div>
            <p>And <strong>remember</strong> to come back to this page to see which options are best.</p>
            <button
              type="button"
              className="btn btn-primary mb-4"
              onClick={() => props.onHide()}
            >
              Ok - Got it!
            </button>
          </div>
    </StyledModal>);
  }

export default ShareModal;
