import React, {useState} from 'react';

function EditEventDetail(props) {
  const [editMode, setEditMode] = useState(false);

  const inputDetailId = "editdetail_"+props.label;
     
  function SaveButton(key) {
      let inputnode = document.getElementById(inputDetailId);
      props.onUpdate(inputnode.value);
  }
  function CancelButton(key) {
      //Do we need to do anything?
  }
  
  if (editMode) {

    return (<p>
            <span style={{paddingRight: "1em"}}>{props.label}:</span>
            <input id={inputDetailId} type="text" defaultValue={props.text} />
            <button onClick={() => {SaveButton(props.label); setEditMode(false);}}>Save</button>
            <button onClick={() => {CancelButton(props.label); setEditMode(false);}}>Cancel</button>
            </p>);
  } else {
    let displayedText = props.text;
    
    if (!displayedText) {
      displayedText = "(unset)";
    }
    let editbutton = "";
    
    if (props.editable) {
      editbutton = <button onClick={()=>{setEditMode(true);}}>Edit</button>;
    }
    return (<p>
              <span style={{paddingRight: "1em"}}>{props.label}:</span>
              <span style={{paddingRight: "1em"}}>{displayedText}</span>
              {editbutton}
            </p>);
  }
}
  
export default EditEventDetail;
