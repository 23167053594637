import React, {} from 'react';
import styled from 'styled-components';
import { Modal } from 'react-overlays'


const Backdrop = styled("div")`
  position: fixed;
  z-index: 1040;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.5;
`;

const StyledModal = styled(Modal)`
  position: fixed;
  width: 20em;
  height: 16em;
  top: 50%;
  left: 50%;
  margin-top: -8em; //half height
  margin-left: -10em; //half width
  z-index: 1040;
  border: 1px solid #e5e5e5;
  background-color: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  padding: 20px;
`;

function SaveCandidateModal(candidate, saveFn) {
  const valuedateinput = document.getElementById('editcandidatedate').value;
  const valuetimeinput = document.getElementById('editcandidatetime').value;
  const valuetextinput = document.getElementById('editcandidatetext').value;
  
  let candidateinfo = {};
  
  if (valuedateinput !== "") {
    candidateinfo['valuedate'] = valuedateinput;
  }

  if (valuetimeinput !== "") {
    candidateinfo['valuetime'] = valuetimeinput;
  }

  candidateinfo['valuetext'] = valuetextinput;

  if (   candidate
      && candidate.candidateid !== 0) {
    candidateinfo['candidateid'] = candidate.candidateid;
  }
  
  saveFn(candidateinfo);
}



function EditCandidateModal(props) {
  const renderBackdrop = (bkgprops) => <Backdrop {...bkgprops} />;

  //console.log("edit candidate modal props: "+JSON.stringify(props));
  let titleBanner;
  let defaultdate;
  let defaulttime;
  let defaulttext;
  let originalcandidate;

  if (   props.candidateInfo 
      && props.candidateInfo.candidate
      && props.candidateInfo.candidate.candidateid !== 0) {
    titleBanner = <h4 id="modal-label">Change Option:</h4>
    defaultdate       = props.candidateInfo.candidate.valuedate;
    defaulttime       = props.candidateInfo.candidate.valuetime;
    defaulttext       = props.candidateInfo.candidate.valuetext;
    originalcandidate = props.candidateInfo.candidate;
    
    // console.log(  'Setting up edit candidate modal for candidate: '
    //              + props.candidateInfo.candidate.candidateid); 
  } else {
    titleBanner = <h4 id="modal-label">Add an Option:</h4>
    defaultdate       = null;
    defaulttime       = null;
    defaulttext       = null;
    originalcandidate = null;
    
    //console.log(  'Setting up add candidate modal'); 
  }
  
  return (
    <StyledModal
          show={props.candidateInfo.show}
          onHide={() => props.onHide()}
          renderBackdrop={renderBackdrop}
          aria-labelledby="modal-label"
        >
          <div>
            {titleBanner}
            <p>
              Set at least one of date, time or text:
            </p>
            <table>
              <tbody>
                <tr>
                  <td>Date:</td>
                  <td><input id="editcandidatedate" type="date" defaultValue={defaultdate} /></td>
                </tr>
                <tr>
                  <td>Time</td>
                  <td><input id="editcandidatetime" type="time" defaultValue={defaulttime} /></td>
                </tr>
                <tr>
                  <td colSpan="2">
                    Text:<br/>
                    <input id="editcandidatetext" type="text" defaultValue={defaulttext} />
                  </td>
                </tr>
                <tr>
                  <td>
                    <button
                      type="button"
                      className="btn btn-primary mb-4"
                      onClick={() => {SaveCandidateModal(originalcandidate, props.candidateInfo.savefn);props.onHide()} }
                    >
                      Save
                    </button>
                  </td>
                  <td style={{textAlign: "right"}}>
                    <button
                      type="button"
                      className="btn btn-primary mb-4"
                      onClick={() => props.onHide()}
                    >
                      Cancel
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
    </StyledModal>);
  }

export default EditCandidateModal;
