import React, {useRef,useReducer} from 'react';

import { Overlay, useDropdownMenu } from 'react-overlays'
import styled from 'styled-components'
import {MenuItem} from '../Dropdown';

import './AbrooMenu.css';
import hamburger from '../../images/hamburger-menu.svg';

const Tooltip = styled("div")`
  position: absolute;
`;

const MenuContainer = styled("div")`
  display: "flex";
  min-width: 150px;
  position: absolute;
  z-index: 1000;
  flex-direction: column;
  border: 1px solid #e5e5e5;
  background-color: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
`;

const Menu = (props) => {
  const { uDMProps } = useDropdownMenu({
    flip: true,
    offset: [0, 0],
  });
  
  //console.log("MenuProps: "+JSON.stringify(props));
  const role = props.role;
  const hide = props.hide;
  
  let menuitems = [];
  
  props.itemdata.forEach(function(item, idx) {
    //console.log('Adding: '+JSON.stringify(item));
    menuitems.push(React.createElement(
                   MenuItem,{
                     className: "abroomenu-selecthover abroomenu",
                     onClick: () => {hide(); item.hitfn();},
                     key: idx,
                     }, 
                     [item.name]));
  });
  return (
    <MenuContainer {...uDMProps} role={role}>
      {menuitems}
    </MenuContainer>
  );
};

const initialSstate = {
  show: false,
};

function reducer(state, [type, payload]) {
  switch (type) {
    case "show":
      return { ...state, show: true };
    case "hide":
      return { ...state, show: false};
    default:
      return state;
  }
}

function AbrooMenu(props) {
  const [{ show }, dispatch] = useReducer(
    reducer,
    initialSstate
  );
  const triggerRef = useRef(null);

  const handleClick = () => {
    if (show) {
      dispatch(["hide"]);
    } else {
      dispatch(["show"]);
    }
  };
  
  const itemdata = props.children;
  
  //console.log("AbrooMenuProps: "+JSON.stringify(props));
  
  function getBody() {
    return document.getElementsByTagName("BODY")[0]; 
  }
  
  function hideMenu() {
    dispatch(["hide"]);
  }

   return (
    <>
      <button
        type="button"
        className="AbrooMenuButton"
        ref={triggerRef}
        onClick={handleClick}
      >
        <img src={hamburger} className="AbrooMenuButtonImage" alt="dropdownmenu" />
      </button>
      <Overlay
        show={show}
        rootClose
        offset={[0, 0]}
        onHide={() => dispatch(["hide"])}
        placement="bottom"
        container={getBody}
        target={triggerRef}
      >
        {({ props }) => (
          <Tooltip {...props}>
             <Menu itemdata={itemdata} role="menu" hide={hideMenu} />
          </Tooltip>
        )}
      </Overlay>
    </>
  );
}


export default AbrooMenu;
