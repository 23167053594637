import {useEffect, useReducer} from 'react';
import axios from 'axios';
import AbrooAPIReducer,{AbrooVoteValues, AbrooLogLevels} from './AbrooAPIResponseHandler.js'

const useAbrooAPI = (initialShortcut, uiData) => {

  //The below line is replaced by the one after it during CleverCloud deploy. So don't
  //alter it without altering the CC deploy...
  const baseurl = 'https://app.whenshallwe.com/v1/evt/';
  //const baseurl = 'https://abrooalpha.cleverapps.io/v1/evt/';
  
  const [state, dispatch] = useReducer(AbrooAPIReducer, {
    shortcut: initialShortcut,
    inflightOps: [],
    numErrors: 0,
    evtData: {},
    tabular: {columns: [], data: []},
    uiData: uiData,
    reloadRequested: true,  //We want to do an initial request of data
  });

  useEffect(() => {
    const fetchInitialData = async () => {
      let actionid = 'start';
      
      if (state.shortcut) {
        actionid += '_'+state.shortcut;

        dispatch({ type: 'startLoadEvent',
                   id: actionid });

        try {
          const url = baseurl + 's/' +state.shortcut;
          const result = await axios(url);
 
          dispatch({ type:    'completedLoadEvent',
                     id:      actionid,
                     shortcutid: state.shortcut,
                     payload: result.data });
        } catch (error) {
          dispatch({ type: 'failedLoadEvent',
                     id:   actionid,
                     shortcutid: state.shortcut,
                     error:  error });
        }
      } else {
        let reqpayload = {
          votetype: "yesnoinconvenient",
        };
    
        dispatch({ type: 'startNewEvent',
                   id: actionid });

        try {
          const url = baseurl + 'election/new';
          const result = await axios({
                                 url: url,
                                 method: "POST",
                                data: reqpayload});

          dispatch({ type: 'completedNewEvent',
                     id: actionid,
                     payload: result.data });
        } catch (error) {
          dispatch({ type:  'failedNewEvent',
                     id: actionid,
                     error:     error });
        }        
      }
    };
    if (state.reloadRequested) {
      fetchInitialData();
    }
  }, [state.reloadRequested, state.shortcut]);

  function generateLocalId(recordType) {
    //TODO: hacky global var - reimplement
    if (typeof window.localidcounter == 'undefined') {
      window.localidcounter = 0;
    }
    window.localidcounter++;
    return 'local_'+window.localidcounter;
  }
  
  function isLocalId(id) {
    if (id && id.startsWith) {
      if (id.startsWith("local_")) {
        return true;
      }
    }
    return false;
  }
  
  //levels defined in AbrooLogLevels
  const log = (level, messagestr) => {
    dispatch({type: 'log', 
              level: level,
              message: messagestr});
  }
  
  const asyncEditEvent = async (evtdetails) => {
    const actionid = "alterevent_" + generateLocalId();
    
    let reqpayload = {
      shortcutid: state.shortcut,
      election: {...evtdetails},
    };
    
    delete reqpayload['election']['electionid']; //we don't include this in the REST req

    dispatch({type: 'startAlterEvent',
              id: actionid,
              event: evtdetails});

    try {
      const url = baseurl + 'election/update';
      const result = await axios({
                            url: url,
                            method: "POST",
                            data: reqpayload});

      dispatch({ type: 'completedAlterEvent',
                 id: actionid,
                 event: evtdetails, 
                 payload: result.data });
    } catch (error) {
        dispatch({ type:      'failedAlterEvent',
                   id: actionid,
                   event: evtdetails, 
                   error:     error });
    }  
  };

  const asyncAddCandidate = async (newCandidate) => {
    const newcandpayload = {
      shortcutid: state.shortcut,
      candidate: newCandidate
    };

    newCandidate['localcandidateid'] = generateLocalId();
    //temporarily use our localid as the real id until we have one
    newCandidate['candidateid'] = newCandidate['localcandidateid'];
    
    const actionid = "newcand_"+newCandidate['localcandidateid'];

    dispatch({type: 'startAddCandidate',
              id: actionid,
              candidate: newCandidate});

    try {
      const url = baseurl + 'candidate/new';
      const result = await axios({
                            url: url,
                            method: "POST",
                            data: newcandpayload});

      dispatch({ type: 'completedAddCandidate',
                 id: actionid,
                 candidate: newCandidate, 
                 payload: result.data });
    } catch (error) {
        dispatch({ type:      'failedAddCandidate',
                   id: actionid,
                   candidate: newCandidate, 
                   error:     error });
    }
  };
  
  const asyncEditCandidate = async (candidate) => {
    const actionid = "altercandidate_"+candidate['candidateid'];
    
    let reqpayload = {
      shortcutid: state.shortcut,
      candidate: {...candidate},
    };
    
    delete reqpayload['candidate']['electionid']; //we don't include this in the REST req
    
    dispatch({type: 'startAlterCandidate',
              id: actionid,
              candidate: candidate});

    try {
      const url = baseurl + 'candidate/update';
      const result = await axios({
                            url: url,
                            method: "POST",
                            data: reqpayload});

      dispatch({ type: 'completedAlterCandidate',
                 id: actionid,
                 candidate: candidate, 
                 payload: result.data });
    } catch (error) {
        dispatch({ type:      'failedAlterCandidate',
                   id: actionid,
                   candidate: candidate, 
                   error:     error });
    }
  };

  const asyncDelCandidate = async (delCandidateId) => {
    const delcandpayload = {
      shortcutid: state.shortcut,
      candidateid: delCandidateId
    };
    const actionid = "delcand_"+delCandidateId;

    //TODO: Handle it being a local id by queueing update
    dispatch({type: 'startDelCandidate',
              id: actionid,
              candidateid: delCandidateId});

    try {
      const url = baseurl + 'candidate/delete';
      const result = await axios({
                            url: url,
                            method: "POST",
                            data: delcandpayload});

      dispatch({ type: 'completedDelCandidate',
                 id: actionid,
                 candidateid: delCandidateId, 
                 payload: result.data });
    } catch (error) {
        dispatch({ type:      'failedDelCandidate',
                   id: actionid,
                   candidateid: delCandidateId, 
                   error:     error });
    }
  };

  const asyncAddVoter = async (newVoter) => {
    const newcandpayload = {
      shortcutid: state.shortcut,
      voter: newVoter
    };

    newVoter['localvoterid'] = generateLocalId();
    //temporarily use our localid as the real id until we have one
    newVoter['voterid'] = newVoter['localvoterid'];
    
    const actionid = "newvoter_"+newVoter['localvoterid'];

    dispatch({type: 'startAddVoter',
              id: actionid,
              voter: newVoter});

    try {
      const url = baseurl + 'voter/new';
      const result = await axios({
                            url: url,
                            method: "POST",
                            data: newcandpayload});

      dispatch({ type: 'completedAddVoter',
                 id: actionid,
                 voter: newVoter, 
                 payload: result.data });
    } catch (error) {
        dispatch({ type:      'failedAddVoter',
                   id: actionid,
                   voter: newVoter, 
                   error:     error });
    }
  };
  
  const asyncEditVoter = async (voter) => {
    const actionid = "altervoter_"+voter['voterid'];
    
    let reqpayload = {
      shortcutid: state.shortcut,
      voter: {...voter},
    };
    
    delete reqpayload['voter']['electionid']; //we don't include this in the REST req
    
    dispatch({type: 'startAlterVoter',
              id: actionid,
              voter: voter});

    try {
      const url = baseurl + 'voter/update';
      const result = await axios({
                            url: url,
                            method: "POST",
                            data: reqpayload});

      dispatch({ type: 'completedAlterVoter',
                 id: actionid,
                 voter: voter, 
                 payload: result.data });
    } catch (error) {
        dispatch({ type:      'failedAlterVoter',
                   id: actionid,
                   voter: voter, 
                   error:     error });
    }
  };

  const asyncDelVoter = async (delVoterId) => {
    const delcandpayload = {
      shortcutid: state.shortcut,
      voterid: delVoterId
    };
    const actionid = "delvoter_"+delVoterId;

    //TODO: Handle it being a local id by queueing update
    dispatch({type: 'startDelVoter',
              id: actionid,
              voterid: delVoterId});

    try {
      const url = baseurl + 'voter/delete';
      const result = await axios({
                            url: url,
                            method: "POST",
                            data: delcandpayload});

      dispatch({ type: 'completedDelVoter',
                 id: actionid,
                 voterid: delVoterId, 
                 payload: result.data });
    } catch (error) {
        dispatch({ type:      'failedDelVoter',
                   id: actionid,
                   voterid: delVoterId, 
                   error:     error });
    }
  };
  
  const asyncSetChoice = async (oldchoice, voterid, candidateid, newvotevalue) => {
    if (oldchoice == null) {
      const newchoice = {
        voterid: voterid,
        candidateid: candidateid,
        votevalue: newvotevalue,
      };

      const reqpayload = {
          shortcutid: state.shortcut,
          choice: { ...newchoice},
        };
      newchoice['localchoiceid'] = generateLocalId();
      //temporarily use our localid as the real id until we have one
      newchoice['choiceid'] = newchoice['localchoiceid'];
      
      const actionid = "newchoice_"+newchoice['localchoiceid'];

      dispatch({type: 'startAddChoice',
                id: actionid,
                choice: newchoice});
                  
      //Send rest call and dispatch result
      try {
        const url = baseurl + 'choice/new';

        const result = await axios({
                          url: url,
                          method: "POST",
                          data: reqpayload});

        dispatch({   type: 'completedAddChoice',
                       id: actionid,
                   choice: newchoice, 
                  payload: result.data });
      } catch (error) {
        dispatch({   type:  'failedAddChoice',
                       id: actionid,
                   choice: newchoice, 
                    error: error });
      }
    } else if (isLocalId(oldchoice['choiceid'])) {
        //TODO: Argh - we are updating a choice that hasn't been fully
        //      created yet - we need a queue mechanism or stop updates to things in flight
        alert('Queueing updates not yet implemented');
    } else {
      const updatedChoice = {
        ...oldchoice,
         votevalue: newvotevalue,
      };
      delete updatedChoice['electionid']; //we don't include this in the REST req

      const actionid = "alterchoice_"+updatedChoice['choiceid'];
      
      dispatch({type: 'startAlterChoice',
                id: actionid,
                choice: updatedChoice});
                 
      //Send rest call and dispatch result
      try {
        const url = baseurl + 'choice/update';
        
        const reqpayload = {
          shortcutid: state.shortcut,
          choice: updatedChoice,
        };
        const result = await axios({
                          url: url,
                          method: "POST",
                          data: reqpayload});

        dispatch({   type: 'completedAlterChoice',
                       id: actionid,
                   choice: updatedChoice, 
                  payload: result.data });
      } catch (error) {
        dispatch({   type: 'failedAlterChoice',
                       id: actionid,
                   choice: updatedChoice, 
                    error: error });
      }
    }
  };

  const asyncDelChoice = async (oldchoice, voterid, candidateid) => {
    if (oldchoice == null) {
      //TODO: Deleting a choice we can't find... not an alert - what shuold we do
      alert("Deleting a choice that we can't find! Reload the page?");
    } else if (isLocalId(oldchoice['choiceid'])) {
      //TODO: Argh - we are updating a choice that hasn't been fully
      //      created yet - we need a queue mechanism or stop updates to things in flight
      alert('Queueing updates not yet implemented');
    } else {
      const actionid = "delchoice_"+oldchoice['choiceid'];
      
      dispatch({   type: 'startDelChoice',
                     id: actionid, 
              delchoice: oldchoice});
               
      //Send rest call and dispatch result
      try {
        const url = baseurl + 'choice/delete';
        const reqpayload = {
          shortcutid: state.shortcut,
          choiceid: oldchoice['choiceid'],
        };
        const result = await axios({
                          url: url,
                          method: "POST",
                          data: reqpayload});

        dispatch({      type: 'completedDelChoice',
                          id: actionid,
                   delchoice: oldchoice, 
                     payload: result.data });
      } catch (error) {
        dispatch({   type: 'failedDelChoice',
                       id: actionid,
                   choice: oldchoice, 
                    error: error });
      }
    }
  };
  
  const allowedToAlter = (recordtype, accesslevel, voterid, allowed_voterid) => {
    //Some fake access functions used in testing:
    //if (voterid != null && voterid == allowed_voterid) return true;
    //if (voterid === 522) return true;
    //return false;

    switch (recordtype) {
      case 'event':
        return (accesslevel === "admin");
      case 'candidate':
        return (accesslevel === "admin");
      case 'voter':
        if (  accesslevel === "admin"
            ||accesslevel === "altervotersandvote") {
          return true;    
        }
        if (  accesslevel === "individualvoter" ) {
          if (   voterid === allowed_voterid
              && allowed_voterid !== null) {
            return true;
          }
        }
        break;
      default:
        //No action for default
    }
    
    return false;
  };
  
  const updateFns = {
    'editEvent':       asyncEditEvent,
    'addCandidate':    asyncAddCandidate,
    'editCandidate':   asyncEditCandidate,
    'deleteCandidate': asyncDelCandidate,
    'addVoter':        asyncAddVoter,
    'editVoter':       asyncEditVoter,
    'deleteVoter':     asyncDelVoter,
    'setChoice':       asyncSetChoice,
    'deleteChoice':    asyncDelChoice,
    'allowedToAlter':  allowedToAlter,
    'log': log,
  };

  return [state, updateFns]
}

export default useAbrooAPI;
export {AbrooVoteValues,AbrooLogLevels};
